import styled from 'styled-components';
import { Section } from '@bitcoin-portal/bitcoincom-pkg-components';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const ApiSection = styled(Section)`
  background-color: ${colors.solid.zircon};
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.background.contrast};
`;

export const HeadSection = styled.div`
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

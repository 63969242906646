import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Nav, Footer } from '@bitcoin-portal/bitcoincom-universal';
import {
  H1,
  H2,
  H3,
  H4,
  Link,
  ContentBlock,
  Code,
  Paragraph,
} from '@bitcoin-portal/bitcoincom-pkg-components';
import { Wrapper, HeadSection, ApiSection } from './styled';
import SEO from './SEO';

const Faq = ({ locale, intl: { messages } }) => (
  <>
    <SEO />
    <Wrapper>
      <HeadSection>
        <Nav locale={locale} contrast />
      </HeadSection>
      <ApiSection>
        <ContentBlock>
          <H1>
            <FormattedMessage id="api.header.title" />
          </H1>
          <Paragraph>
            <Link href="/">
              <FormattedMessage id="api.header.home" />
            </Link>{' '}
            / <FormattedMessage id="api.header.api" />
          </Paragraph>
          <H2>
            <FormattedMessage id="api.header.subtitle" />
          </H2>
          <Paragraph>
            <FormattedMessage id="api.header.desc" />
          </Paragraph>
        </ContentBlock>
        <ContentBlock>
          <H3>
            <FormattedMessage id="api.methods.newProof.title" />
          </H3>
          <H4>
            <FormattedMessage id="api.methods.descLabel" />
          </H4>
          <Paragraph>
            <FormattedMessage id="api.methods.newProof.desc" />
          </Paragraph>
          <H4>
            <FormattedMessage id="api.methods.post" />
          </H4>
          <Code copyable>https://notary-api.bitcoin.com/api/v1/proofs</Code>
          <H4>
            <FormattedMessage id="api.methods.exampleCurl" />
          </H4>
          <Code
            bordered
            copyable
            language={undefined}
            maxHeight={undefined}
            startingLineNumber={1}
            useInlineStyles
          >
            {`curl -X POST "https://notary-api.bitcoin.com/api/v1/proofs" -d "3f772cc2096a317100504d7958ab4c5467729f49205f7ca8bcac703807f71e27"

          `}
          </Code>
          <H4>
            <FormattedMessage id="api.methods.example" />
          </H4>
          <Code
            bordered
            copyable
            language={undefined}
            maxHeight={undefined}
            startingLineNumber={1}
            useInlineStyles
          >
            {`const hash = '3f772cc2096a317100504d7958ab4c5467729f49205f7ca8bcac703807f71e27'
          fetch('https://notary-api.bitcoin.com/api/v1/proofs', {
            method: 'POST',
            body: hash,
          })
            .then(response => response.json())
            .then(
              response => {
                console.log(response);
              },
              err => {
                console.log(err);
              },
            );

            /* Successful response from notary.bitcoin.com
                {
                  "status": "ok",
                  "url": "https://notary.bitcoin.com/proof/?hash=3f772cc2096a317100504d7958ab4c5467729f49205f7ca8bcac703807f71e27",
                  "address": "bitcoincash:qqxzcazdwqgsl6ja8yrcly3kwxmvuyp0xgazj8henh",
                  "price": 5000,
                  "anchored": false,
                }
            */
          `}
          </Code>
          <H3>
            <FormattedMessage id="api.methods.proofStatus.title" />
          </H3>
          <H4>
            <FormattedMessage id="api.methods.descLabel" />
          </H4>
          <Paragraph>
            <FormattedMessage id="api.methods.proofStatus.desc" />
          </Paragraph>
          <H4>
            <FormattedMessage id="api.methods.get" />
          </H4>
          <Code
            copyable
          >{`https://notary-api.bitcoin.com/api/v1/proofs/<sha256hash>`}</Code>
          <H4>
            <FormattedMessage id="api.methods.exampleCurl" />
          </H4>
          <Code
            bordered
            copyable
            language={undefined}
            maxHeight={undefined}
            startingLineNumber={1}
            useInlineStyles
          >
            {`curl "https://notary-api.bitcoin.com/api/v1/proofs/3f772cc2096a317100504d7958ab4c5467729f49205f7ca8bcac703807f71e27"
          `}
          </Code>
          <H4>
            <FormattedMessage id="api.methods.example" />
          </H4>
          <Code
            bordered
            copyable
            language={undefined}
            maxHeight={undefined}
            startingLineNumber={1}
            useInlineStyles
          >
            {`const hash = '3f772cc2096a317100504d7958ab4c5467729f49205f7ca8bcac703807f71e27'
          fetch(https://notary-api.bitcoin.com/api/v1/proofs/\${hash})
          .then(response => response.json())
          .then(
            response => {
              console.log(response);
            }
            err => {
              console.log(err)
            }
          )

          /* Sample successful response from notary.bitcoin.com
          {
            "status": "ok",
            "data": {
              "id": 3,
              "hash": "3f772cc2096a317100504d7958ab4c5467729f49205f7ca8bcac703807f71e27",
              "address": "127NN3Q4QHLqVMeTMeefCVVNdie3whcbpq",
              "price": 5000,
              "stamp": 1585179532,
              "bcast": null,
              "anchor": [],
              "funds": [],
              "state": "unpaid",
              "balance": 5000
            }
          }
          */
          `}
          </Code>
        </ContentBlock>
      </ApiSection>
      <Footer locale={locale} />
    </Wrapper>
  </>
);

Faq.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.object,
  }).isRequired,
};
export default injectIntl(Faq);
